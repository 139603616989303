import { collection, getDocs, query } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import Select from "react-select"

import { Regions } from "../../constants/findPropertyData"
import { db } from "../../firebase/config"
import { FindPropertyContext } from "./context"
import  country  from "../../assets/countriesStates.json";

const LeftOptionsContent = ({ categoryParameters, countryParameters }) => {
  const [propertyTypes, setPropertyTypes] = useState([])
  const [countries, setCountries] = useState([])

  getDocs(collection(db, "countries")).then(documents => {
    if (documents) {
      let results = []
      documents.forEach(document => {
        results.push(document.data())
      })
      setCountries(results)
    }
  })

  // useContext
  const { setCategoryValue, setCountryValue } = useContext(FindPropertyContext)

  useEffect(() => {
    const q = query(collection(db, "property_type"))
    const data = []
    getDocs(q).then(documents => {
      if (documents) {
        documents.forEach(type => {
          data.push({ value: type.id, label: type.data().title })
        })
        setPropertyTypes(data)
      }
    })
  }, [])

  // styling
  const categorySelectStyles = {
    control: () => ({
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      borderBottom: "1px solid rgba(255,255,255,0.2)",
    }),
    singleValue: () => ({
      color: "white",
      paddingBottom: "15px",
      fontSize: "0.88rem",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      paddingBottom: "0px",
      fill: "white",
    }),
  }

  const OptionsSelectStyles = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: styles => ({
      ...styles,
      fill: "#477B11",
    }),
    singleValue: styles => ({
      ...styles,
      color: "#4F4F4F",
      fontSize: "0.77rem",
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "0.77rem",
    }),
  }
  const tabel = country.map(type => {
    return { value: type.name, label: type.name }
  })
  return (
    <>
      <div className="category">
        <label htmlFor="category">CATEGORY</label>
        <Select
          options={propertyTypes}
          defaultValue={
            Object.keys(categoryParameters).length !== 0
              ? categoryParameters
              : propertyTypes[0]
          }
          onChange={e => setCategoryValue({ label: e.label, value: e.value })}
          isSearchable={false}
          id="category"
          styles={categorySelectStyles}
        />
      </div>
      <div className="other-options">
        <div className="option">
          <label htmlFor="country">COUNTRY</label>
          <Select
            id="country"
            options={tabel}
            placeholder="Please select a country"
            styles={OptionsSelectStyles}
            defaultValue={
              Object.keys(countryParameters).length !== 0
                ? countryParameters
                : propertyTypes[0]
            }
            onChange={e => setCountryValue({ label: e.label, value: e.value })}
            isSearchable={false}
          />
        </div>
        <div className="option">
          <label htmlFor="region">Governate</label>
          <input
          className="state"
            type='text'
            id="region"
            options={Regions}
            placeholder="Please select a state"
            styles={OptionsSelectStyles}
          />
        </div>
        {/* <div className="option">
          <label htmlFor="date">DATE</label>
          <input type="date" name="date" id="date" className="date-input" />
        </div> */}
      </div>
    </>
  )
}

export default LeftOptionsContent
