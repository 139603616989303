import React, { useContext } from "react"
import { FindPropertyContext } from "./context"
import { ToggleExpandable } from "./FindPropertyContent.styles"

const Toggler = ({ optionsExpanded }) => {
  const { setOptionsExpanded } = useContext(FindPropertyContext)
  return (
    <ToggleExpandable onClick={() => setOptionsExpanded(!optionsExpanded)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="18"
        viewBox="0 0 21 18"
      >
        <path
          id="Path_184"
          data-name="Path 184"
          d="M0,65.5A1.5,1.5,0,0,1,1.5,64h18a1.5,1.5,0,0,1,0,3H1.5A1.5,1.5,0,0,1,0,65.5ZM0,73a1.5,1.5,0,0,1,1.5-1.5h18a1.5,1.5,0,0,1,0,3H1.5A1.5,1.5,0,0,1,0,73Zm19.5,9H1.5a1.5,1.5,0,0,1,0-3h18a1.5,1.5,0,0,1,0,3Z"
          transform="translate(0 -64)"
          fill="#11587b"
        />
      </svg>
    </ToggleExpandable>
  )
}

export default Toggler
