import styled from "styled-components"

export const Container = styled.div`
  padding: 70px 0;

  .container {
    display: flex;
    gap: 10px;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
`

export const LeftOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  .state{
    border-radius: 5px;
    border: 1px solid #dddddd;
    padding: 6px;
  }
  @media (max-width: 992px) {
    width: 100%;
  }

  .category {
    padding: 20px;
    background-color: var(--lightGreen);

    label {
      color: white;
      font-size: 0.66rem;
    }
  }
  .other-options {
    padding: 20px;
    background-color: rgba(143, 143, 143, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      display: block;
      color: var(--darkBlue);
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 0.66rem;
    }

    .date-input {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #dddddd;
    }
  }
`

export const ExpndableOptions = styled.div`
  @media (max-width: 992px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 10px;
    background-color: white;
    z-index: 9;
    overflow-y: scroll;
    transform: ${({ isExpanded }) =>
      isExpanded ? "translateY(0%)" : "translateY(calc(100% - 40px))"};
    transition: 0.4s ease-in;
    box-shadow: 0px 0px 8px #dddddd;
  }
`

export const ToggleExpandable = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
  }
`

export const ClosingContent = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const UpperContentClosingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
  border-bottom: 1px solid #dddddd;

  label {
    color: var(--darkBlue);
    font-weight: bold;
    font-size: 0.66rem;
  }

  svg {
    transform-origin: center;
    transform: rotateZ(${({ isOpen }) => (isOpen ? "0" : "-90deg")});
  }
`

export const LowerContentClosingContent = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  .check-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .check-container {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 5px;

      input {
        appearance: none;
        width: 30px;
        height: 30px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        flex-shrink: 0;
        position: relative;

        &:checked {
          &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 4px;
            left: 4px;
            border-radius: 3px;
            background-color: var(--lightGreen);
          }
        }
      }

      label {
        color: var(--darkText);
        font-size: 0.77rem;
      }
    }

    .number {
      color: var(--lightGreen);
      font-weight: bold;
      font-size: 0.77rem;
    }
  }

  @media (max-width: 992px) {
    max-height: 110px;
    overflow: scroll;
  }
`

export const RightContent = styled.div`
  width: 80%;

  @media (max-width: 992px) {
    width: 100%;
  }

  .no-listings {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .alert {
      width: 60px;
    }
  }
`

export const RightOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  label {
    display: block;
    color: var(--lightBlue);
    font-weight: bold;
    font-size: 0.66rem;
  }

  .search-option {
    width: 70%;

    @media (max-width: 1200px) {
      width: 60%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    .search-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 13px;

      input {
        width: 90%;
        border: none;
        font-size: 0.88rem;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .sort-option,
  .show-option {
    width: 15%;

    @media (max-width: 1200px) {
      width: 20%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const Listings = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  .listing {
    width: 32%;

    @media (max-width: 992px) {
      width: 49%;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }
`

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 40px;
    background: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--lightGreen);
    border: 1px solid var(--lightGreen);
    font-size: 0.77rem;

    svg {
      width: 12px;
    }
  }
`
