import React, { useContext } from "react"
import Hero from "../components/Hero"
import FindPropertyContent from "../components/FindPropertyContent"
import InternalAds from "../components/InternalAds"
import ListingsCarousel from "../components/ListingsCarousel"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import Widgets from "../helpers/widgets"
import { StateContext } from "../helpers/StateProvider"

const FindAProperty = props => {
  let parameters = {}
  if (props.location.state) {
    if (Object.keys(props.location.state) !== 0) {
      parameters = props.location.state
    }
  }
  const { language, setLanguage } = useContext(StateContext)

  const { authIsReady } = useAuthContext()
  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="Find A Property" />
            <Hero
              title={language == false ? "Find A Property" : "البحث عن عقار"}
              subTitle={
                language == false
                  ? "Fill in the blanks or choose from dropdowns to find your demands"
                  : "املأ الفراغات أو اختر من القوائم المنسدلة للعثور على مطالبك"
              }
              arabic={language}
            />
            <FindPropertyContent parameters={parameters} arabic={language} />
            <Widgets
              widgetsData="find-a-property"
              data={props.data}
              arabic={language}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allPage(filter: { name: { eq: "find-a-property" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }

    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`
export default FindAProperty
