import { collection, getDocs, query } from "firebase/firestore"
import { db } from "../firebase/config"

export const filterListings = (
  salePriceValue,
  rentPriceValue,
  bedroomsValue,
  bathroomsValue,
  facilitiesValue,
  categoryValue,
  countryValue,
  sortValue,
  showResultValue,
  searchValue
) => {
  return new Promise(resolve => {
    // -------------- NEW PROCESS ------------------

    // Getting all the properties from firebase
    let ref = collection(db, "property")
    const q = query(ref)
    getDocs(q).then(snapshot => {
      let returnedArray = []
      snapshot.forEach(doc => {
        returnedArray.push({ id: doc.id, data: doc.data() })
      })

      // filtering through sale price value first
      if (salePriceValue.length) {
        if (salePriceValue[0] !== 1000 && salePriceValue[1] !== 1000000) {
          returnedArray = returnedArray.filter(value => {
            return (
              parseInt(value.data.sale_price, 10) >=
                salePriceValue[0].toString() &&
              parseInt(value.data.sale_price, 10) <=
                salePriceValue[1].toString()
            )
          })
        } else {
          if (salePriceValue[0] === 1000 && salePriceValue[1] !== 1000000) {
            returnedArray = returnedArray.filter(value => {
              return (
                parseInt(value.data.sale_price, 10) <=
                salePriceValue[1].toString()
              )
            })
          }
          if (salePriceValue[0] !== 1000 && salePriceValue[1] !== 100000) {
            returnedArray = returnedArray.filter(value => {
              return (
                parseInt(value.data.sale_price, 10) >=
                salePriceValue[0].toString()
              )
            })
          }
        }
      }

      // filtering based on the rent price
      if (rentPriceValue.length) {
        if (rentPriceValue[0] !== 50 && rentPriceValue[1] !== 6000) {
          returnedArray = returnedArray.filter(value => {
            return (
              parseInt(value.data.rent_price, 10) >= rentPriceValue[0] &&
              parseInt(value.data.rent_price, 10) <= rentPriceValue[1]
            )
          })
        } else {
          if (rentPriceValue[0] === 50 && rentPriceValue[1] !== 6000) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.rent_price, 10) <= rentPriceValue[1]
            })
          }
          if (rentPriceValue[0] !== 50 && rentPriceValue[1] === 6000) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.rent_price, 10) >= rentPriceValue[0]
            })
          }
        }
      }

      // filtering based on the bedrooms values
      if (bedroomsValue.length) {
        if (bedroomsValue[0] !== 1 && bedroomsValue[1] !== 10) {
          returnedArray = returnedArray.filter(value => {
            return (
              parseInt(value.data.num_bedrooms, 10) >= bedroomsValue[0] &&
              parseInt(value.data.num_bedrooms, 10) <= bedroomsValue[1]
            )
          })
        } else {
          if (bedroomsValue[0] === 1 && bedroomsValue[1] !== 10) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.num_bedrooms, 10) <= bedroomsValue[1]
            })
          }
          if (bedroomsValue[0] !== 1 && bedroomsValue[1] === 10) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.num_bedrooms, 10) >= bedroomsValue[0]
            })
          }
        }
      }

      // filtering based on the bathrooms values
      if (bathroomsValue.length) {
        if (bathroomsValue[0] !== 1 && bathroomsValue[1] !== 10) {
          returnedArray = returnedArray.filter(value => {
            return (
              parseInt(value.data.num_bathrooms, 10) >= bathroomsValue[0] &&
              parseInt(value.data.num_bathrooms, 10) <= bathroomsValue[1]
            )
          })
        } else {
          if (bathroomsValue[0] === 1 && bathroomsValue[1] !== 10) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.num_bathrooms, 10) <= bathroomsValue[1]
            })
          }
          if (bathroomsValue[0] !== 1 && bathroomsValue[1] === 10) {
            returnedArray = returnedArray.filter(value => {
              return parseInt(value.data.num_bathrooms, 10) >= bathroomsValue[0]
            })
          }
        }
      }

      // filtering based on the facilities
      if (facilitiesValue.length) {
        facilitiesValue.forEach(item => {
          returnedArray = returnedArray.filter(property => {
            return property.data.checkedAmenities.some(amenity => {
              return amenity.value === item.value
            })
          })
        })
      }

      // filtering based on category
      if (Object.keys(categoryValue).length !== 0) {
        returnedArray = returnedArray.filter(property => {
          return property.data.type.value === categoryValue.value
        })
      }

      // filtering based on country
      if (Object.keys(countryValue).length !== 0) {
        returnedArray = returnedArray.filter(property => {
          return property.data.country.value === countryValue.value
        })
      }

      // filtering based on search value
      if (!!searchValue) {
        const term = searchValue.toLowerCase()
        returnedArray = returnedArray.filter(property => {
          return property.data.title.toLowerCase().indexOf(term) !== -1
        })
      }
      // Sorting
      if (Object.keys(sortValue) !== 0) {
        switch (sortValue.value) {
          case "rent_price":
            returnedArray.sort((a, b) => {
              return parseInt(a.data.rent_price) - parseInt(b.data.rent_price)
            })
            break
          case "sale_price":
            returnedArray.sort((a, b) => {
              return parseInt(a.data.sale_price) - parseInt(b.data.sale_price)
            })
            break
          case "area":
            returnedArray.sort((a, b) => {
              return parseInt(a.data.area) - parseInt(b.data.area)
            })
            break
          default:
            break
        }
      }

      // Sorting order
      if (Object.keys(showResultValue) !== 0) {
        if (showResultValue.value === "desc") {
          returnedArray.reverse()
        }
      }

      resolve(returnedArray)
    })
  })
}
