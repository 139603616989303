import React, { useContext } from "react"

import { RightOptions } from "./FindPropertyContent.styles"
import Select from "react-select"
import { SortBy } from "../../constants/findPropertyData"
import { SortBy_ar } from "../../constants/findPropertyData"
import { FindPropertyContext } from "./context"

const RightOptionsStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: styles => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "unset",
    borderBottom: "1px solid #DDDDDD",
    fontSize: "0.88rem",
  }),
}

const RightOptionsContent = ({ searchValue,arabic }) => {
  const { setSortValue, setShowResultValue, setSearchValue } =
    useContext(FindPropertyContext)

  return (
    <RightOptions>
      <div className="search-option">
        <label htmlFor="search">SEARCH</label>
        <div className="search-container">
          <input
            type="search"
            name=""
            id="search"
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value)
            }}
            placeholder={arabic==false?"What property are you looking for?":"ما نوع العقار الذي تبحث عنه؟"}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.006"
            height="18.006"
            viewBox="0 0 18.006 18.006"
          >
            <path
              id="Path_6"
              data-name="Path 6"
              d="M20.71,19.29l-3.4-3.39A7.92,7.92,0,0,0,19,11a8,8,0,1,0-8,8,7.92,7.92,0,0,0,4.9-1.69l3.39,3.4a1,1,0,1,0,1.42-1.42ZM5,11a6,6,0,1,1,6,6,6,6,0,0,1-6-6Z"
              transform="translate(-3 -3)"
              fill="#477b11"
            />
          </svg>
        </div>
      </div>
      <div className="sort-option">
        <label htmlFor="">SORT BY</label>
        <Select
          id="sort"
          options={arabic==false?SortBy:SortBy_ar}
          onChange={e => {
            setSortValue({ label: e.label, value: e.value })
          }}
          styles={RightOptionsStyles}
        />
      </div>
      <div className="show-option">
        <label htmlFor="show">SHOW RESULT</label>
        <Select
          id="show"
          onChange={e => {
            setShowResultValue({ label: e.label, value: e.value })
          }}
          options={[
            { label: "Ascending", value: "asc" },
            { label: "Descending", value: "desc" },
          ]}
          styles={RightOptionsStyles}
        />
      </div>
    </RightOptions>
  )
}

export default RightOptionsContent
