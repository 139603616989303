export const CategoryOptions = [
  { value: "residential", label: "Residential" },
  { value: "commercial", label: "Commercial" },
  { value: "vacant-land", label: "Vacant Land" },
]

export const Countries = [
  { value: "lebanon", label: "Lebanon" },
  { value: "usa", label: "United States of America" },
  { value: "france", label: "France" },
]

export const Regions = [
  { value: "1", label: "Region 1" },
  { value: "2", label: "Region 2" },
  { value: "3", label: "Region 3" },
]

export const Types = [
  { value: "villas", label: "Villas", count: "50", label_ar: "فيلات" },
  { value: "apartment", label: "Apartment", count: "40", label_ar: "شقة" },
  { value: "studio", label: "Studio", count: "10", label_ar: "الاستوديو" },
  { value: "bungalow", label: "Bungalow", count: "30", label_ar: "شقة" },
  { value: "cabin", label: "Cabin", count: "12" },
  { value: "cave-house", label: "Cave House", count: "13", label_ar: "شقة" },
  { value: "chalet", label: "Chalet", count: "45", label_ar: "شقة" },
]

export const RentPrices = {
  max: 6000,
  min: 50,
  step: 100,
  minOriginal: 50,
  maxOriginal: 6000,
}

export const SalePrices = {
  max: 1000000,
  min: 1000,
  step: 1000,
  minOriginal: 1000,
  maxOriginal: 1000000,
}

export const Guests = {
  max: 10,
  min: 1,
  step: 1,
  minOriginal: 1,
  maxOriginal: 10,
}

export const NumberOfBedrooms = {
  max: 10,
  min: 1,
  step: 1,
  minOriginal: 1,
  maxOriginal: 10,
}

export const NumberOfBathrooms = {
  max: 10,
  min: 1,
  step: 1,
  minOriginal: 1,
  maxOriginal: 10,
}

export const Facilities = [
  { value: "concierge", label: "Concierge", count: "50" },
  { value: "parking", label: "Parking", count: "10" },
  { value: "generator", label: "Generator", count: "15" },
]

export const Features = [
  { value: "kitchen", label: "Kitchen", count: "10" },
  { value: "living-room", label: "Living Room", count: "2" },
  { value: "terrace", label: "Terrace", count: "13" },
  { value: "lift", label: "Lift", count: "54" },
  { value: "pool", label: "Pool", count: "12" },
  { value: "Solar System", label: "Solar System", count: "17" },
]

export const Securities = [
  { value: "fire-extinguisher", label: "Fire Extinguisher", count: "30" },
  {
    value: "cctv-outside-property",
    label: "CCTV outside property",
    count: "30",
  },
  { value: "cctv-inside-property", label: "CCTV Inside property", count: "15" },
  { value: "smoke-alarms", label: "Smoke Alarms", count: "10" },
]

export const SortBy = [
  { value: "rent_price", label: "Rent Price" },
  { value: "sale_price", label: "Sale Price" },
  { value: "area", label: "Area" },
]
export const SortBy_ar = [
  { value: "rent_price", label: "سعر الإيجار" },
  { value: "sale_price", label: "سعر البيع" },
  { value: "area", label: "المساحة" },
]
