import React, { useState } from "react"

import { Types } from "../../constants/findPropertyData"
import { useCollection } from "../../hooks/useCollection"

import {
  ClosingContent,
  UpperContentClosingContent,
  LowerContentClosingContent,
} from "./FindPropertyContent.styles"

const TypeContent = () => {
  const [typeState, setTypeState] = useState(false)
  const [types, setTypes] = useState([])

  useCollection("property_type").then(documents => {
    if (documents) {
      setTypes(documents)
    }
  })

  return (
    <>
      <ClosingContent>
        <UpperContentClosingContent
          onClick={() => setTypeState(!typeState)}
          isOpen={typeState}
        >
          <label htmlFor="type-toggle">TYPE</label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.828"
            height="8.414"
            viewBox="0 0 14.828 8.414"
          >
            <path
              id="arrow"
              d="M20.5,11.5l-6,6-6-6"
              transform="translate(-7.086 -10.086)"
              fill="none"
              stroke="#477b11"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </svg>
        </UpperContentClosingContent>
        <LowerContentClosingContent isOpen={typeState}>
          {types.map(type => {
            return (
              <div className="check-content" key={type.id}>
                <div className="check-container">
                  <input
                    type="checkbox"
                    name={type.data.id}
                    id={type.data.id}
                    className="check"
                  />
                  <label htmlFor={type.data.id}>{type.data.title}</label>
                </div>
                <div className="number">({type.count})</div>
              </div>
            )
          })}
        </LowerContentClosingContent>
      </ClosingContent>
    </>
  )
}

export default TypeContent
