import React, { useState, useContext } from "react"
import { Range, getTrackBackground } from "react-range"
import { FindPropertyContext } from "./context"

const TwoThumbs = ({ rtl, min, max, step, initialMin, initialMax, method }) => {
  // context
  const {
    setSalePriceValue,
    setRentPriceValue,
    setBedroomsValue,
    setBathroomsValue,
  } = useContext(FindPropertyContext)

  const [values, setValues] = useState([initialMin, initialMax])

  // handling the change of the range selector read the provided method and set value accordingly
  const handleChange = values => {
    setValues(values)
    switch (method) {
      case "salePrice":
        setSalePriceValue(values)
        break
      case "rentPrice":
        setRentPriceValue(values)
        break
      case "bedrooms":
        setBedroomsValue(values)
        break
      case "bathrooms":
        setBathroomsValue(values)
        break
      default:
        break
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={values => {
          handleChange(values)
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "90%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#F2F2F2", "#477B11", "#F2F2F2"],
                  min: min,
                  max: max,
                  rtl,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              backgroundColor: "#477B11",
            }}
          ></div>
        )}
      />
      <output
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        id="output"
      >
        <p
          style={{
            color: "#4F4F4F",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {values[0].toFixed(1)}
        </p>
        <p
          style={{
            color: "#4F4F4F",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {values[1].toFixed(1)}
        </p>
      </output>
    </div>
  )
}

export default TwoThumbs
