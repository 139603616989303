import { collection, getDocs } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"

import { db } from "../../firebase/config"
import { FindPropertyContext } from "./context"

import {
  ClosingContent,
  UpperContentClosingContent,
  LowerContentClosingContent,
} from "./FindPropertyContent.styles"

const FacilitiesContent = ({ facilitiesValue }) => {
  const { setFacilitiesValue } = useContext(FindPropertyContext)

  const [facilities, setFacilities] = useState()

  useEffect(() => {
    getDocs(collection(db, "amenity")).then(documents => {
      if (documents) {
        let results = []
        documents.forEach(doc => {
          results.push({ id: doc.id, data: doc.data() })
        })
        setFacilities(results)
      }
    })
  }, [])

  // handling change in facilities checkbox
  const handleFacilityChange = e => {
    let newArr = facilitiesValue
    if (e.target.checked) {
      setFacilitiesValue([
        ...newArr,
        {
          label: e.target.ariaLabel,
          value: e.target.value,
        },
      ])
    } else {
      newArr = newArr.filter(value => {
        return value.value != e.target.value
      })
      setFacilitiesValue(newArr)
    }
  }

  const [facilitiesState, setFacilitiesState] = useState(false)
  return (
    <ClosingContent>
      <UpperContentClosingContent
        onClick={() => setFacilitiesState(!facilitiesState)}
        isOpen={facilitiesState}
      >
        <label htmlFor="type-toggle">FACILITIES</label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.828"
          height="8.414"
          viewBox="0 0 14.828 8.414"
        >
          <path
            id="arrow"
            d="M20.5,11.5l-6,6-6-6"
            transform="translate(-7.086 -10.086)"
            fill="none"
            stroke="#477b11"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </svg>
      </UpperContentClosingContent>
      <LowerContentClosingContent isOpen={facilitiesState}>
        {facilities &&
          facilities.map(facility => {
            return (
              <div className="check-content" key={facility.id}>
                <div className="check-container">
                  <input
                    type="checkbox"
                    name="check-facility"
                    id={facility.data.value}
                    className="check"
                    aria-label={facility.data.title}
                    value={facility.data.value}
                    onChange={handleFacilityChange}
                  />
                  <label htmlFor={facility.data.value}>
                    {facility.data.title}
                  </label>
                </div>
                <div className="number">{facility.count}</div>
              </div>
            )
          })}
      </LowerContentClosingContent>
    </ClosingContent>
  )
}

export default FacilitiesContent
