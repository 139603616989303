import React, { useState, useEffect, useRef } from "react"

import {
  Container,
  LeftOptions,
  RightContent,
  Listings,
  LoadMore,
  ExpndableOptions,
} from "./FindPropertyContent.styles"
import NotifcationAlert from "../../assets/icons/dashboard/notification-alert.svg"

import ListingsCarouselItem from "../ListingsCarouselItem"

// components
import LeftOptionsContent from "./LeftOptionsContent"
import TypeContent from "./TypeContent"
import Toggler from "./Toggler"
import SalePriceContent from "./SalePriceContent"
import BedroomsContent from "./BedroomsContent"
import BathroomsContent from "./BathroomsContent"
import FacilitiesContent from "./FacilitiesContent"
import RightOptionsContent from "./RightOptionsContent"
import RentPriceContent from "./RentPriceContent"

import { useAuthContext } from "../../hooks/useAuthContext"

import {
  collection,
  doc,
  getDoc,
  getDocs,
  where,
  orderBy,
  query,
} from "firebase/firestore"
import { db } from "../../firebase/config"

// context
import { FindPropertyContext } from "./context"
import { filterListings } from "../../helpers/filterListings"

const FindPropertyContent = ({ parameters, arabic }) => {
  const { user } = useAuthContext()

  // initialize the listings
  const [listings, setListings] = useState([])
  const [noListingsFound, setNoListingsFound] = useState(false)

  // values
  const [typeValue, setTypeValue] = useState([])
  const [rentPriceValue, setRentPriceValue] = useState([])
  const [salePriceValue, setSalePriceValue] = useState([])
  const [bedroomsValue, setBedroomsValue] = useState([])
  const [bathroomsValue, setBathroomsValue] = useState([])
  const [facilitiesValue, setFacilitiesValue] = useState([])
  const [categoryValue, setCategoryValue] = useState({})
  const [countryValue, setCountryValue] = useState({})
  const [sortValue, setSortValue] = useState({})
  const [showResultValue, setShowResultValue] = useState({})
  const [searchValue, setSearchValue] = useState("")
  const [limit, setLimit] = useState(15)

  // expand options
  const [optionsExpanded, setOptionsExpanded] = useState(false)

  // Setting the listings
  useEffect(() => {
    if (parameters.category || parameters.country || parameters.text) {
      parameters.category && setCategoryValue(parameters.category)
      parameters.country && setCountryValue(parameters.country)
      parameters.text && setSearchValue(parameters.text.value)
    } else {
      const fetchNav = async () => {
        const q = query(collection(db, "property"), orderBy("date", "desc"))
        const querySnapshot = await getDocs(q)
        let arr = []
        querySnapshot.forEach(doc => {
          arr.push({ id: doc.id, data: doc.data() })
        })
        setListings(arr)
      }

      fetchNav()
    }
  }, [parameters.category, parameters.country, parameters.text])

  const [liked_properties, setLikedProperties] = useState([])

  useEffect(() => {
    // getting the liked properties
    if (user) {
      getDoc(doc(db, "users", user.uid)).then(document => {
        if (document.data()) {
          setLikedProperties(document.data().liked_properties)
        }
      })
    }
  }, [])

  // getting the location
  const location = window.navigator && window.navigator.geolocation

  const initial = useRef(true)

  useEffect(() => {
    if (initial.current) {
      initial.current = false
      return
    }

    const timer = setTimeout(() => {
      filterListings(
        salePriceValue,
        rentPriceValue,
        bedroomsValue,
        bathroomsValue,
        facilitiesValue,
        categoryValue,
        countryValue,
        sortValue,
        showResultValue,
        searchValue
      ).then(newListings => {
        if (newListings.length) {
          setListings(newListings)
          setNoListingsFound(false)
        } else {
          setNoListingsFound(true)
        }
      })
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [
    rentPriceValue,
    salePriceValue,
    bedroomsValue,
    bathroomsValue,
    facilitiesValue,
    categoryValue,
    countryValue,
    sortValue,
    showResultValue,
    searchValue,
  ])

  return (
    <FindPropertyContext.Provider
      value={{
        setTypeValue,
        setRentPriceValue,
        setSalePriceValue,
        setBedroomsValue,
        setBathroomsValue,
        setFacilitiesValue,
        setOptionsExpanded,
        setCategoryValue,
        setCountryValue,
        setSortValue,
        setShowResultValue,
        setSearchValue,
      }}
    >
      <Container>
        <div className="container">
          <LeftOptions>
            <LeftOptionsContent
              categoryParameters={
                parameters.category ? parameters.category : {}
              }
              countryParameters={parameters.country ? parameters.country : {}}
            />
            <ExpndableOptions isExpanded={optionsExpanded}>
              <Toggler optionsExpanded={optionsExpanded} />
              <TypeContent typeValue={typeValue} />
              <SalePriceContent salePriceValue={salePriceValue} />
              <RentPriceContent rentPriceValue={rentPriceValue} />
              <BedroomsContent bedroomsValue={bedroomsValue} />
              <BathroomsContent bathroomsValue={bathroomsValue} />
              <FacilitiesContent facilitiesValue={facilitiesValue} />
            </ExpndableOptions>
          </LeftOptions>
          <RightContent listing={listings}>
            <RightOptionsContent arabic={arabic} searchValue={searchValue} />
            {listings && !noListingsFound && (
              <Listings>
                {listings.slice(0, limit).map(listing => {
                  const isLiked = liked_properties.includes(listing.id)
                  return (
                    <ListingsCarouselItem
                      location={location}
                      listing={listing}
                      isLiked={isLiked}
                      user={user}
                      key={listing.id}
                      arabic={arabic}
                    />
                  )
                })}
              </Listings>
            )}
            {noListingsFound && (
              <div className="no-listings">
                <img src={NotifcationAlert} className="alert" alt="" />
                <h4>
                  {arabic == false
                    ? "Sorry no listings where found"
                    : "آسف، لا توجد قوائم بهذه المواصفات"}
                </h4>
                <p>
                  {arabic == false
                    ? "Change your filters and try again"
                    : "آسف، لا توجد قوائم بهذه المواصفات"}
                </p>
              </div>
            )}
            <LoadMore>
              {listings && listings.length >= limit && (
                <button onClick={() => setLimit(limit + 3)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.449"
                    height="13.409"
                    viewBox="0 0 14.449 13.409"
                  >
                    <g
                      id="Group_116"
                      data-name="Group 116"
                      transform="translate(-1100.314 -2937.7)"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M0,0H11.737"
                        transform="translate(1107.605 2937.7) rotate(90)"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M0,13.044,6.436,6.43,0,0"
                        transform="translate(1114.055 2943.268) rotate(90)"
                        fill="none"
                        stroke="#477b11"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                  <span>Load More</span>
                </button>
              )}
            </LoadMore>
          </RightContent>
        </div>
      </Container>
    </FindPropertyContext.Provider>
  )
}

export default FindPropertyContent
