import React, { useState } from "react"

import {
  ClosingContent,
  UpperContentClosingContent,
  LowerContentClosingContent,
} from "./FindPropertyContent.styles"
import TwoThumbs from "./RangeSelector"

import { NumberOfBathrooms } from "../../constants/findPropertyData"

const BathroomsContent = () => {
  const [bathroomsState, setBathroomsState] = useState(false)
  return (
    <ClosingContent>
      <UpperContentClosingContent
        onClick={() => setBathroomsState(!bathroomsState)}
        isOpen={bathroomsState}
      >
        <label htmlFor="type-toggle">BATHROOMS</label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.828"
          height="8.414"
          viewBox="0 0 14.828 8.414"
        >
          <path
            id="arrow"
            d="M20.5,11.5l-6,6-6-6"
            transform="translate(-7.086 -10.086)"
            fill="none"
            stroke="#477b11"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </svg>
      </UpperContentClosingContent>
      <LowerContentClosingContent isOpen={bathroomsState}>
        <TwoThumbs
          min={NumberOfBathrooms.min}
          max={NumberOfBathrooms.max}
          step={NumberOfBathrooms.step}
          initialMin={NumberOfBathrooms.minOriginal}
          initialMax={NumberOfBathrooms.maxOriginal}
          method="bathrooms"
        />
      </LowerContentClosingContent>
    </ClosingContent>
  )
}

export default BathroomsContent
