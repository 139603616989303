import React, { useState } from "react"
import TwoThumbs from "./RangeSelector"
import { NumberOfBedrooms } from "../../constants/findPropertyData"

import {
  ClosingContent,
  UpperContentClosingContent,
  LowerContentClosingContent,
} from "./FindPropertyContent.styles"

const BedroomsContent = () => {
  const [bedroomsState, setBedroomsState] = useState(false)
  return (
    <ClosingContent>
      <UpperContentClosingContent
        onClick={() => setBedroomsState(!bedroomsState)}
        isOpen={bedroomsState}
      >
        <label htmlFor="type-toggle">BEDROOMS</label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.828"
          height="8.414"
          viewBox="0 0 14.828 8.414"
        >
          <path
            id="arrow"
            d="M20.5,11.5l-6,6-6-6"
            transform="translate(-7.086 -10.086)"
            fill="none"
            stroke="#477b11"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </svg>
      </UpperContentClosingContent>
      <LowerContentClosingContent isOpen={bedroomsState}>
        <TwoThumbs
          min={NumberOfBedrooms.min}
          max={NumberOfBedrooms.max}
          step={NumberOfBedrooms.step}
          initialMin={NumberOfBedrooms.minOriginal}
          initialMax={NumberOfBedrooms.maxOriginal}
          method="bedrooms"
        />
      </LowerContentClosingContent>
    </ClosingContent>
  )
}

export default BedroomsContent
